<template>
  <div class="ride-with-us">
    <PageHeading
      :title="'Rely on us for your family member’s transit needs'"
      :text="'At MedDrive.Health, we prioritize the safety and well-being of your family members during their medical journeys.  We understand the importance of timely and reliable service for those with medical needs, and our team is committed to meeting and exceeding your expectations. Trust in our expertise for all your family\'s non-emergency medical transportation requirements.'"
    />
    <RowBlock :items="services" />
    <TimelineBlock :items="howItWorks" :title="'How it works'" />
  </div>
</template>

<script>
import PageHeading from "../components/PageHeading.vue";
import TimelineBlock from "../components/TimelineBlock.vue";
import RowBlock from "../components/RowBlock.vue";

export default {
  name: "RideForFamily",
  components: {
    PageHeading,
    TimelineBlock,
    RowBlock,
  },
  data: () => ({
    howItWorks: [
      {
        img: "images/family/family-itswork-1.jpg",
        title: `<a href="/register" style="text-decoration: underline;">Sign Up</a> with MedDrive.Health`,
        text: "Family members will register for MedDrive online portal to schedule transportation for their loved ones, by providing essential details such as patient needs, language preferences, round trip and more.",
      },
      {
        img: "images/family/family-itswork-2.jpg",
        title: "MedDrive.Health will assign a driver",
        text: "The assigned driver will introduce themselves and confirm pickup times one day prior to the scheduled transportation.",
      },
      {
        img: "images/family/family-itswork-3.jpg",
        title: "Monitor Status",
        text: "Track your loved one's journey with ease through our mobile app, and communicate with the driver at any point during the trip.",
      },
    ],
    services: [
      {
        img: "images/family/family-services-1.jpg",
        //title: "Lorem ipsum dolor",
        text: "Guide our passengers to the medical office by providing attentive, step-by-step assistance throughout the entire process.",
      },
      {
        img: "images/family/family-services-2.png",
        //title: "Lorem ipsum dolor sit amet",
        text: "Enjoy convenience with driver introduction and pickup time confirmation provided one day in advance.",
      
      },
      {
        img: "images/family/family-services-3.jpg",
        //title: "Lorem ipsum dolor sit amet consectetur",
        text: "Live status monitoring and updates to keep family members informed throughout the journey.",
      
      },
      {
        img: "images/family/family-services-4.jpg",
        //title: "Lorem ipsum dolor",
        text: "Drivers remain on standby, guaranteeing a maximum of 15-minute wait time, and in most cases, are waiting for patients upon appointment completion.",
      
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
 .ride-with-us {
  display: flex;
  flex-flow: column;
  align-items: center;
  
  &__main {
    background: $primary-color;
  }

  &__contact-us {
    width: 100%;
    padding: 120px 25px;

    background: #ffffff;

    position: relative;
    z-index: 2;

    @media screen and (max-width: 940px) {
      padding: 60px 25px;
    }
  }
}
</style>