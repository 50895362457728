<template>
  <section class="row-block">
    <div class="row-block__body">
      <div
        class="row-block__title"
        :class="{ 'with-description': description }"
        v-if="title"
      >
        {{ title }}
      </div>
      <div class="row-block__description" v-if="description">
        {{ description }}
      </div>
      <div class="row-block__list">
        <div
          class="row-block__item"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="row-block__item-img">
            <img :src="require('@/assets/' + item.img)" alt="" />
          </div>
          <div class="row-block__item-title">
            {{ item.title }}
          </div>
          <div class="row-block__item-text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      value: String,
      default: null,
    },
    description: {
      value: String,
      default: null,
    },
    items: {
      value: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.row-block {
  width: 100%;
  padding: 100px 0;

  background: $primary-color;

  @media screen and (max-width: 940px) {
    padding: 60px 0;
  }

  &__body {
    width: 100%;
    max-width: $containerMaxWidth;
    margin: 0px auto;

    padding: 0 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
  }

  &__title {
    @include adaptiv-font(40, 26);
    font-weight: 700;
    text-align: center;
    color: $white;

    &.with-description {
      margin-bottom: 40px !important;
    }

    margin-bottom: 100px;

    @media screen and (max-width: 690px) {
      margin-bottom: 60px;
    }
  }

  &__description {
    @include adaptiv-font(24, 18);
    font-weight: 500;
    text-align: center;
    color: $white;
    line-height: 140%;

    margin-bottom: 80px;

    @media screen and (max-width: 690px) {
      margin-bottom: 40px;
    }
  }

  &__list {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, minmax(290px, 1fr));
    grid-row-gap: 50px;

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, minmax(290px, 1fr));
    }

    @media screen and (max-width: 690px) {
      grid-template-columns: repeat(1, minmax(260px, 1fr));
    }
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;

    padding: 0 25px;

    text-align: center;
    color: $white;

    &:not(:last-child) {
      border-right: 1px solid rgb(255, 255, 255, 0.3);

      &:nth-child(2n) {
        @media screen and (max-width: 1200px) {
          border-right: none;
        }
      }
    }

    @media screen and (max-width: 690px) {
      padding: 0;
      border-right: none !important;
    }
  }

  &__item-img {
    width: 250px;
    height: 250px;

    margin-bottom: 40px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__item-title {
    @include adaptiv-font(32, 20);
    font-weight: 600;
    line-height: 120%;

    margin-bottom: 30px;
  }

  &__item-text {
    @include adaptiv-font(20, 17);
    font-weight: 500;
    line-height: 130%;
  }
}
</style>